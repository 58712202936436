import PageLayout from "layouts/PageLayout";
import TableData from "./tableData";

function Enquiry() {
  return (
    <PageLayout
      title={'Enquiry'}
    >
      <TableData />
    </PageLayout>
  );
}

export default Enquiry;
